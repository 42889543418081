<template>
  <div>
    <Invoices />
  </div>
</template>

<script>
import Invoices from "@/components/portal/Invoices";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Invoices,
  },
  metaInfo: {
    title: "Facturen beheren | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>
